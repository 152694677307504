<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      v-model="isValid"
      v-if="!success && !isLoading && !hasError"
    >
      <v-text-field
        v-model="form.firstName"
        :counter="25"
        :rules="nameRules"
        label="Name *"
        required
      ></v-text-field>

      <v-text-field
        v-model="form.lastName"
        :counter="25"
        :rules="surnameRules"
        label="Surname *"
        required
      ></v-text-field>

      <v-text-field
        v-model="form.schoolName"
        :counter="25"
        :rules="schoolRules"
        label="School Name *"
        required
        v-if="isCorporateInput"
      ></v-text-field>

      <v-text-field
        v-model="form.businessName"
        :counter="25"
        :rules="businessRules"
        label="Business Name *"
        required
        v-if="isBussinessInput && !isCorporateInput"
      ></v-text-field>

      <v-text-field
        v-model="form.contactNumber"
        :counter="25"
        :rules="contactNumberRules"
        label="Contact Number *"
        required
      ></v-text-field>

      <v-text-field
        v-model="form.email"
        :rules="emailRules"
        label="E-mail *"
        required
      ></v-text-field>

      <v-btn 
        rounded 
        dark 
        :disabled="!isValid" 
        class="mr-4" 
        @click="submitForm"
      >Submit</v-btn>
    </v-form>

    <v-dialog v-model="isLoading" max-width="290">
      <v-card>
        <v-card-text
          class="text-center pink-primary"
          style="padding: 16px !important;"
        >
          <v-progress-circular 
            :size="70" 
            indeterminate 
            class="primary--text" 
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
​
<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        formName: this.user?.formName || "",
        name: this.user?.firstName || "",
        surname: this.user?.lastName || "",
        schoolName: this.user?.schoolName || "",
        businessName: this.user?.businessName || "",
        cell: this.user?.contactNumber || "",
        email: this.user?.email || "",
      },
      success: false,
      isLoading: false,
      isCorporateInput: true,
      isBussinessInput: false,
      isValid: false,
      hasError: false,
      apikey: "sLE0ikW3xojPFXsDHYW460phH2HhvKq0",
      enquiryFormData: [],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 25) || "Name must be less than 25 characters",
      ],
      surnameRules: [
        (v) => !!v || "Surname is required",
        (v) =>
          (v && v.length <= 25) || "surname must be less than 25 characters",
      ],
      schoolRules: [
        (v) => !!v || "School name is required",
        (v) =>
          (v && v.length <= 25) ||
          "The school name must be less than 25 characters",
      ],
      businessRules: [
        (v) => !!v || "Business name is required",
        (v) =>
          (v && v.length <= 25) ||
          "The business name must be less than 25 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) || "E-mail must be valid",
      ],
      contactNumberRules: [
        (v) => !!v || "Contact number is required",
        (v) =>
          /0((60[3-9]|64[0-5]|66[0-5])\d{6}|(7[1-4689]|6[1-3]|8[1-4])\d{7})/.test(
            v
          ) || "Contact number is not valid, please enter only numbers",
        (v) =>
          (v && v.length <= 10) ||
          "The contact number must be less than 10 characters",
      ],
    };
  },
  watch: {
    isValid() {
      if (this.$refs.form.validate() === false) {
        return (this.isValid = false);
      }

      return (this.isValid = true);
    },
  },
  mounted() {
    if (this.form.formName !== "CorporateSocialInvestment") {
      this.isBussinessInput = true;
      this.isCorporateInput = false;
    }

    if (this.isValid) {
      return (this.isValid = false);
    }
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.success = true;
      let formObj = this.form;

      for (let key in formObj) {
        if (
          typeof key === "string" &&
          key === "formName" &&
          formObj[key] !== "CorporateSocialInvestment"
        ) {
          delete formObj["schoolName"];
          this.enquiryFormData.push({
            source: "FTTB",
            name: this.form.firstName,
            surname: this.form.lastName,
            cell: this.form.contactNumber,
            organisation: this.form.businessName,
            email: this.form.email,
            updates: false,
            marketing: false,
            champion: false,
            mdu_name: null,
            mdu_unit: null,
            str_num: null,
            str_name: null,
            lat: null,
            lng: null,
            hood: "",
            city: "",
            postal: "",
            form_add: "",
            channel: "",
            place_id: "",
          });
        } else {
          delete formObj["businessName"];
          this.enquiryFormData.push({
            name: this.form.firstName,
            source: "CSI",
            surname: this.form.lastName,
            cell: this.form.contactNumber,
            organisation: this.form.schoolName,
            email: this.form.email,
            updates: false,
            marketing: false,
            champion: false,
            mdu_name: null,
            mdu_unit: null,
            str_num: null,
            str_name: null,
            lat: null,
            lng: null,
            hood: "",
            city: "",
            postal: "",
            form_add: "",
            channel: "",
            place_id: "",
          });
        }
      }

      if (
        Array.isArray(this.enquiryFormData) &&
        this.enquiryFormData.length > 0
      ) {
        const url = `${process.env.VUE_APP_PANGEA_BASE_URL}/orders/show_my_interest_requests/`;
        const config = {
          headers: {
            apikey: this.apikey,
            "Content-type": "application/json",
          },
        };

        const formData = this.enquiryFormData[0];
        this.axios
          .post(url, formData, config)
          .then((response) => {
            if (response.status === 200) {
              this.isLoading = false;
              this.$router.push({ name: "SmiThankYou" });
            }
          })
          .catch((error) => {
            this.$store.commit("setHasError", true);
            this.success = false;
            this.isLoading = false;
            error = null;
            if (screen.width < 600) {
              window.scroll({
                top: 1646,
                behavior: "smooth",
              });
            } else {
              window.scroll({
                top: 2756,
                behavior: "smooth",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.enquiryMessage {
  resize: none;
}

.reach-products-wrap {
  max-width: 1150px;
  width: 100%;
  margin: 2rem auto 0;
}

.v-progress-circular {
  margin: 1rem;
}
</style>